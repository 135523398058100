import { render, staticRenderFns } from "./ProductPage.vue?vue&type=template&id=45d68aba"
import script from "./ProductPage.vue?vue&type=script&lang=js"
export * from "./ProductPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControlPanel: require('/home/userapp/components/Molecule/ControlPanel.vue').default,PageHeader: require('/home/userapp/components/Organism/PageHeader.vue').default,Matrix: require('/home/userapp/components/Molecule/Matrix/Matrix.vue').default,SharePageSocials: require('/home/userapp/components/Molecule/SharePageSocials.vue').default,Base: require('/home/userapp/components/Template/Base.vue').default})
