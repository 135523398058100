
import { defineComponent } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/translation'

export default defineComponent({
  props: {
    pageTitle: { type: String, required: true, default: '' },
    url: { type: [String, Object], required: true, default: undefined },
  },
  setup(props) {
    return {
      ...setupTranslationPrefixer('page-socials'),
    }
  },
})
